import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { addLink } from './firebaseauth';

const Scraper = ({onClose}) => {
  const [url, setUrl] = useState('');
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [error, setError] = useState('');
  const [shadowDisplay,setShowDisplay]=useState(false);
  const [closeDisplay,setcloseDisplay]=useState(false);
  const [loading, setLoading] = useState('');


  const handlclose=()=>{
    setShowDisplay(!shadowDisplay)
  }
  const handleScrape = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`https://fastify-serverless-function-sand-one.vercel.app/?url=${url}`);
      setImages(response.data.images);
      setVideos(response.data.videos);
      setError('');

      if(response.data.images.length > 0 || response.data.videos.length>0){
        setShowDisplay(true);
        addLink(response.data.images[0].src)
      }

    } catch (err) {
      setError('Failed to fetch data');
      console.error('Error during API call:', err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="absolute z-40 bg-gray-800 text-white p-6 rounded-lg shadow-lg max-w-4xl mx-auto  ">
      <div className="mb-4 flex ">
        <input
          type="text"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="Enter webpage URL"
          className="w-56 text-gray-300 p-3 border border-gray-600 rounded-lg focus:outline-none focus:border-indigo-500"
        />
        <button
          onClick={handleScrape}
          className="m-3 px-6 py-2 bg-indigo-600 text-white font-semibold rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          Scrape
        </button>
      </div>

      {error && (
        <div className="bg-red-600 p-4 rounded-lg mb-4">
          <p>{error}</p>
        </div>
      )}

{shadowDisplay &&

<div className="mb-8">
<h2 className="text-xl font-bold mb-4">Images</h2>
{images.length > 0 ? (
  <ul className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
    {images.map((img, index) => (
      
      <li key={index} className="relative group">

        <img
          src={img.src}
          alt={`Scraped image ${index}`}
          className="w-full h-auto object-cover rounded-lg shadow-md transition-transform duration-300 ease-in-out group-hover:scale-105"
        />
      </li>
    ))}
  </ul>
) : (
  <p className="text-gray-400">No images found</p>
)}
</div>}

{shadowDisplay && <div>
<h2 className="text-xl font-bold mb-4">Videos</h2>
{videos.length > 0 ? (
  <ul className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
    {videos.map((vid, index) => (
      <li key={index} className="relative group">
                              {console.log(vid)}

        <video
          controls
          className="w-full h-auto rounded-lg shadow-md transition-transform duration-300 ease-in-out group-hover:scale-105"
        >
          <source src={vid} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </li>
    ))}
  </ul>
) : (
  <p className="text-gray-400">No videos found</p>
)}
</div>
}
{(shadowDisplay) && <button onClick={()=>handlclose()}>Close</button>}
      
    </div>
  );
};

export default Scraper;
