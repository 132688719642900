import React, { useEffect, useRef, useState } from "react";
import { images } from "./gallery";
import { FaBackward, FaBox, FaFilter, FaGrav, FaGripHorizontal } from "react-icons/fa";
import { addLink, getLinks, UploadImages ,initImages,shuffleArray} from "./firebaseauth";
import Scraper from "./Scraper";
import { Fa42Group, FaA, FaRegPaste, FaShuffle, FaSquareBehance } from "react-icons/fa6";
const LandingPage = () => {
const [ArrImgUrl, uPdateArraImgUlr] = useState(shuffleArray(images));
const [viewset, setViewSet] = useState(5);

const handlviewset=(param)=>{

  setViewSet(param)
}

  function getRandomInRange(min, max, step) {
    const range = (max - min) / step;
    const randomStep = Math.floor(Math.random() * (range + 1));
    return min + randomStep * step;
  }
  const pageSc = {
    cols: viewset,
    rows: ArrImgUrl.length,
  };
  const arrMap = [];

  for (let index = 0; index < pageSc.cols; index++) {
    arrMap.push(index);
  }
  const newLink = useRef("");
  const [error, setError] = useState(null);

  const addMore = async () => {
    const Linkimg = newLink.current.value;
    const url = Linkimg.trim();
    if (url && isValidUrl(url)) {
      // Add image to the list or handle the URL submission
      console.log("Image URL added:", url);
      //uPdateArraImgUlr([url, ...ArrImgUrl]);
      addLink(url);
      setError(null);
      initImages(uPdateArraImgUlr)
    } else {
      setError("Please enter a valid URL.");
    }
  };
  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  };
  const newLinks = useRef("");

  const addMultiImages = () => {
    const Linkimg = newLinks.current.value;

    const urls = Linkimg.split(",");

    console.log(urls);

    const arr = [];
    urls.map((url) => {
      if (url && isValidUrl(url)) {
        arr.push(url);
        setError(null);
      } else {
        setError("Please enter a valid URL.");
      }
    });
    initImages(uPdateArraImgUlr,arr)

    /*
     */
  };
  const [view, setView] = useState(false);
  const [viewImage, setViewTmage] = useState("");
  const [viewclose, setViewclose] = useState(false);
  const indexRef = useRef();
  const handlIamgeView = () => {
    setView(!view);
    indexRef.current = ArrImgUrl[getRandomInRange(0, ArrImgUrl.length, 1)];
  };
const shuffleAgain=()=>{
  //const arr=shuffleArray(ArrImgUrl)
  //uPdateArraImgUlr(Array.from(arr));
  initImages(uPdateArraImgUlr)
  console.log("did shufff")

}
  return (

    <div className="relative w-fit h-fit bg-gray-900" id="top">
      <div className="fixed bottom-4 right-4 opacity-50 hover:opacity-100">
        <a href="#top">
        <span className="bg-orange-500 w-10 h10 rounded p-2 cursor-pointer hover:text-white">UP</span>
        </a>
      </div>
      {view && (
        <div className="absolute w-full h-screen bg-gray-900 z-50 top-0 flex items-center justify-center p-8">
          <span
            onClick={() => {
              setView(!view);
            }}
            className="absolute w-12 h-12 rounded-full hover:bg-red-500 cursor-pointer top-2 left-2 flex items-center justify-center text-white"
          >
            <FaBackward />
          </span>
          <img
            src={indexRef.current}
            alt={indexRef.current}
            style={{ maxWidth: "500px" }}
          />
        </div>
      )}
      {console.log(ArrImgUrl)}
      <div className="relative h-32  bg-gray-800">
        <Scraper onClose={(param)=>{setViewclose(param)}}/>
      </div>
      <div className="relative h-fit mb-2" >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 h-fit">
          <div className="relative">
          <div className="bg-orange-500 w-full h-14 flex items-center justify-between p-3">
            <h3 className="text-white font-bold text-lg" >
              Gallery Pin Collection - ({ArrImgUrl.length})
            </h3>
            <button
              onClick={() => {
                handlIamgeView();
              }}
              className="text-white bg-orange-600 hover:bg-orange-700 px-3 py-1 rounded focus:outline-none"
            >
              View Image
            </button>
            
          </div>
            <div className="h-56 w-auto">
              <h4 className="text-white p-2">Recent photos</h4>
              <div className="flex p-2">
                {ArrImgUrl.map((src,index)=>(
                  index < 5 && <img key={index} src={src} alt="" style={{maxWidth:"80px"}} />
                ))}
              </div>
              
            </div>
              <button
              onClick={(e)=>{e.preventDefault();shuffleAgain()}}
              className="absolute bottom-4 right-8 text-indigo-500 w-12 h-12 p-2 rounded-full hover:bg-gray-500 hover:text-white flex justify-center items-center ">
                <FaShuffle/>
              </button>
              <button
              onClick={()=>{handlviewset(8)}}
              className="absolute bottom-4 right-20 text-indigo-500 w-12 h-12 p-1 rounded-full hover:bg-gray-500 hover:text-white flex justify-center items-center ">
                <FaGripHorizontal/>
              </button>
              <button
              onClick={()=>{handlviewset(4)}}
              className="absolute bottom-4 right-32 text-indigo-500 w-12 h-12 p-1 rounded-full hover:bg-gray-500 hover:text-white flex justify-center items-center ">
                <FaA/>
              </button>
          </div>
          

          <div className="bg-gray-600 p-4 rounded-lg">
            <input
              ref={newLink}
              type="text"
              placeholder="http://imageUrl.jpg"
              className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700"
              onClick={addMore}
            >
              Add Image
            </button>
            <hr className="my-2" />
            {error && <p className="text-red-500 mt-2">{error}</p>}
            <textarea
              ref={newLinks}
              type="text"
              style={{ maxHeight: "200px" }}
              placeholder={`http://imageUrl01.jpg,\nhttp://imageUrl02.jpg,\nhttp://imageUrl01.jpg,\nhttp://imageUrl03.jpg`}
              className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700"
              onClick={addMultiImages}
            >
              Add Multiple Images
            </button>
            {error && <p className="text-red-500 mt-2">{error}</p>}
          </div>
        </div>
      </div>
      <div
        style={{
          gridTemplateColumns: `repeat(${pageSc.cols}, minmax(0, 1fr))`,
        }}
        className={`grid gap-4`}
      >
        {Array.from(arrMap).map((v, index2) => {
          return (
            <div
            key={index2}
              style={{ gridTemplateColumns: `repeat(${1}, minmax(0, 1fr))` }}
              className={`grid gap-4`}
            >
              <>
                {ArrImgUrl.map((src, index) => {
                  return (
                    <img
                    key={index}
                      src={
                        ArrImgUrl[ArrImgUrl.length-(index+index2) -1 ]
                      }
                      alt=""
                      style={{ width: "100%" }}
                      className="bg-gray-900 rounded-lg"
                    />
                  );
                })}
              </>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LandingPage;
